<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">
        Calculate
        <stemble-latex content="$\Delta\text{G}^\circ$" />
        at the temperature given for each of the two reactions shown below based on the provided
        equilibrium constants.
      </p>

      <p class="mb-2">
        <stemble-latex content="$\ce{a) 2A(g) + B(g) <=> 2C(g)}\hspace{1.0cm}$" />
        <stemble-latex content="$\text{K}_\text{p} = $" />
        <number-value :value="Kp1" />
        <stemble-latex content="$\text{ at T} = " />
        <number-value :value="Temp1" />
        <stemble-latex content="$^\circ\text{C}$" />
      </p>

      <calculation-input
        v-model="inputs.deltaG1"
        class="mb-8"
        prepend-text="$\Delta\text{G}^\circ:$"
        append-text="$\text{kJ/mol}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        <stemble-latex content="$\ce{b) AB2(s) <=> A^2+(aq) + 2B-(aq)}\hspace{1.0cm}$" />
        <stemble-latex content="$\text{K}_\text{c} = $" />
        <number-value :value="Kc2" />
        <stemble-latex content="$\text{ at T} = " />
        <number-value :value="Temp2" />
        <stemble-latex content="$^\circ\text{C}$" />
      </p>

      <calculation-input
        v-model="inputs.deltaG2"
        class="mb-2"
        prepend-text="$\Delta\text{G}^\circ:$"
        append-text="$\text{kJ/mol}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';
import NumberValue from '@/tasks/components/NumberValue';

export default {
  name: 'Question301',
  components: {
    NumberValue,
    CalculationInput,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        deltaG1: null,
        deltaG2: null,
      },
    };
  },
  computed: {
    Kp1() {
      return this.taskState.getValueBySymbol('Kp1').content;
    },
    Kc2() {
      return this.taskState.getValueBySymbol('Kc2').content;
    },
    Temp1() {
      return this.taskState.getValueBySymbol('Temp1').content;
    },
    Temp2() {
      return this.taskState.getValueBySymbol('Temp2').content;
    },
  },
};
</script>
